<template>
    <div class="container">
        <div class="row">
            <h1 class="col-12 text-center"> <p v-html="$t('title')"></p></h1>
            
            <div class="col-12 lang_wrap">
                <a class="clickable" @click="changeLocalization('cs')" title="Změna jazyka / Sprache ändern" >
                    <h2 class="text-primary">{{ $t('homepage.cz') }}</h2>
                </a>
                <a class="clickable" @click="changeLocalization('de')" title="Změna jazyka / Sprache ändern">
                    <h2 class="text-primary">{{ $t('homepage.de') }}</h2>
                </a>
            </div>

            <div class="col-12 mt-4">
                <div class="row">
                    <div class="col-12 text-center">
                    </div>
                    <div class="col-12 c-card-wrap">
                        <login></login>
                    </div>
                    <div class="col-12 c-card-wrap mt-3">
                        <b-card>
                            <h1 class="text-center">{{ $t('homepage.registration_q') }}</h1>
                            <router-link :to="{name: 'registration'}" class="btn btn-primary col-12">{{ $t('homepage.register') }}</router-link>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Login from "@/components/login";

export default {
    name: "homepage",
    components: {Login},
    methods: {
        changeLocalization: function (newLang) {
            this.$i18n.locale = newLang;
            localStorage.setItem('local', JSON.stringify(newLang));
            this.newLang = ((newLang) == 'cs') ? 'de' : 'cs';
            this.$store.dispatch('setLocalizationInServer', newLang);
        }
    },
}
</script>

<style lang="scss" scoped>
    .lang_wrap {
        display: flex;
        justify-content: center;
        gap: 0 20px;
        a:hover {
            text-decoration: none;
        }
    }
</style>
